import React from "react";
import P from "./components/P";
import H2 from "./components/H2";
import image2 from "./assets/fs-woman.png";
// import "./App.css";
// import image1 from "./assets/cta-1.png";
// import { BsArrowRight } from "react-icons/bs";
// import H1 from "./components/H1";

const FirstSection = ( ) => {
  return (
    <div className="section-wrapper">
      {/* <div className="first-call-to-action">
        <div className="first-cta-text">
          <H1 delay="400">
            Find the <span className="emphasis">best offer</span> to meet your
            service and supply needs.
          </H1>
          <P delay="400">
            Procurement doesn't have to be a hassle. Join multonion and find the
            most competitive bid in half the time.
          </P>
          <P delay="450">
            Expand your reach and get the most competitive bids from vendors and
            suppliers.
          </P>
          <button
            data-aos="fade-up"
            data-aos-delay="550"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="800"
            className="join-waitlist-btn"
            onClick={() => toggleModal()}
          >
            Join Waitlist
            <BsArrowRight style={{ size: "2rem" }} />
          </button>
        </div>
        <div
          className="cta-image"
          data-aos="fade-left"
          data-aos-delay="300"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
        >
          <img src={image1} alt="happy-procurement-officer" />
        </div>
      </div> */}
      <div className="first-text-section">
        <div className="happy-user">
          {" "}
          <img
            src={image2}
            alt="happy user"
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-duration="800"
            style={{ transform: "translate(50px, 50px)" }}
          />
        </div>

        <div>
          <H2>Seamless Supplier/Vendor Selection</H2>
          <P>
          Reduce cost of search for vendors or supplier for big corporations, government and not-for-profit
          </P>
          <H2>Better Access to Market Opportunities</H2>
          <P>
          Better access to market and reduce cost of search for business opportunities for vendors including SMEs
          </P>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;






