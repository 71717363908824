import React from "react";
import shakingHands from "./assets/shaking-hands.png";
import rings from "./assets/rings.png";
import { BsArrowRight } from "react-icons/bs";
// import dummyAvatar from "./assets/dummy-avatar.png";
// import userIcon from "./assets/users-icon.png";
// import sentIcon from "./assets/sent-icon.png";
// import traction from "./assets/traction-icon.png";
// import H2 from "./components/H2";
// import Slider from "infinite-react-carousel";
// import quotes from "./assets/quotes.png";
// import n from "./assets/n.png";
// import m from "./assets/m.jpg";
// import p from "./assets/p.png";
// import r from "./assets/r.png";
// import s from "./assets/s.png";
// import u from "./assets/u.png";
// import v from "./assets/v.png";
// import a from "./assets/a.png";
// import b from "./assets/b.png";
// import c from "./assets/c.png";
// import e from "./assets/e.png";
// import f from "./assets/f.png";
// import g from "./assets/g.png";

const TestimonialsAndStats = ({ toggleModal }) => {
  // const images = [n, m, p, r, s, u, v, a, b, c, e, f, g];

  // const testimonials = [
  //   {
  //     image: dummyAvatar,
  //     testimonial:
  //       "“I get information from various fields, all from a single source”",
  //     details: "Daniel Papka, Managing Director, Datech Nig. Ltd",
  //   },
  //   {
  //     image: dummyAvatar,
  //     testimonial: "“We get timely information from the platform”",
  //     details:
  //       "Collins Ogah, Business Development Officer, Kinetic Electromechanic Nig. Ltd",
  //   },
  //   {
  //     image: dummyAvatar,
  //     testimonial:
  //       "“It has been very beneficial and it Keeps us informed and up to date”",
  //     details:
  //       "Promise Esu, Procurement Officer, AG Vision Construction Nigeria ltd",
  //   },
  // ];
  return (
    <div className="testimonials">
      {/* <H2>Key Metrics</H2>
      <div className="stat-wrapper">
        <div
          className="stat-card"
          data-aos="fade-left"
          data-aos-delay={500}
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
        >
          <img src={userIcon} alt="" />
          <div>
            <p className="stats" style={{ color: "#068513" }}>
              4,000+
            </p>
            <p>C-suite Users</p>
          </div>
        </div>

        <div
          className="stat-card"
          data-aos="fade-left"
          data-aos-delay={600}
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
        >
          <img src={sentIcon} alt="" />
          <div>
            <p className="stats" style={{ color: "#952292" }}>
              19,000+
            </p>
            <p>Published Opportunities</p>
          </div>
        </div>
        <div
          className="stat-card"
          data-aos="fade-left"
          data-aos-delay={700}
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
        >
          <img src={traction} alt="" />
          <div>
            <p className="stats" style={{ color: "#0170A9" }}>
              $20,000+
            </p>
            <p>Transaction Value</p>
          </div>
        </div>
      </div> */}

      <div className="second-call-to-action">
        <img src={shakingHands} alt="" />
        <div
          className="small-ring"
          data-aos="zoom-in"
          data-aos-delay={500}
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="400"
        >
          {" "}
          <img src={rings} alt="" />
        </div>

        <div className="second-cta-text-wrapper">
          {/* <p
            className="small-descr"
            data-aos="fade-up"
            data-aos-delay={500}
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="800"
          >
            *Do you have the best deal in the market?
          </p> */}
          <h2
            className="header-text-2"
            style={{ color: "#fff", fontSize: "1.8em" }}
            data-aos="fade-up"
            data-aos-delay={600}
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="800"
          >
            Multonion is Africa's B2B operating system for supply chain
            management that makes it easier, cheaper and faster for vendors,
            including SMEs, to access the market and fulfil the supply needs of
            big corporations, not-for-profit and government organisations in
            Africa
          </h2>
          {/* <p
            style={{ color: "#fff" }}
            data-aos="fade-up"
            data-aos-delay={700}
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="800"
          >
            Multonion is Africa's B2B operating system for supply chain
            management that makes it easier, cheaper and faster for vendors,
            including SMEs, to access the market and fulfil the supply needs of
            big corporations, not-for-profit and government organisations in
            Africa
          </p> */}
          <a href="https://app.multonion.com/sign-up">
            <button
              className="transparent-btn"
              data-aos="fade-up"
              data-aos-delay={800}
              data-aos-anchor-placement="bottom-bottom"
              data-aos-duration="800"
              onClick={() => {}}
            >
              Sign Up as a Buyer, Seller or Both
              <BsArrowRight style={{ size: "2rem" }} />
            </button>
          </a>
        </div>
      </div>

      <div
        style={{
          minWidth: "80%",
          marginTop: "5rem",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* <H2>Multonion is Trusted By</H2>
        <div style={{ minWidth: "80%", marginTop: "3rem" }}> */}
        {/* <Slider dots slidesPerRow={5} autoplay arrows={false} style={{}}>
            {" "}
            {images.map((image, i) => (
              <div className="brand-wrapper" key={i}>
                <img src={image} alt="" width="100px" className="brands" />
              </div>
            ))}
          </Slider> */}
        {/* </div> */}
      </div>

      {/* <div className="testimonials-wrapper">
        {testimonials.map((testimonial, i) => (
          <div
            className="testimonial-cards"
            data-aos="fade-left"
            data-aos-delay={800 + i * 100}
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration={800 + i * 50}
            key={i}
          >
            <img src={quotes} alt="" className="testimonial-quotes" /> */}
      {/* <img
              src={testimonial.image}
              alt=""
              className="testimonial-avatars"
            /> */}
      {/* <div>
              <p className="testimonial">{testimonial.testimonial}</p>
              <p className="testifier-details">{testimonial.details}</p>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default TestimonialsAndStats;
