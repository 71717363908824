import React from "react";

const H2 = ({ delay, children }) => {
  return (
    <h3
      data-aos="fade-up"
      data-aos-delay={delay}
      data-aos-anchor-placement="bottom-bottom"
      data-aos-duration="800"
      className="header-text-2"
    >
      {children}
    </h3>
  );
};

export default H2;
