import React from "react";

const P = ({ children, delay }) => {
  return (
    <p
      className="text"
      data-aos="fade-up"
      data-aos-delay={delay}
      data-aos-anchor-placement="bottom-bottom"
      data-aos-duration="800"
    >
      {children}
    </p>
  );
};

export default P;
