import React from "react";
import landingImg from "./assets/supply-chain.png";

// import bid1 from "./assets/bid1.png";
// import bid2 from "./assets/bid2.png";
// import bid3 from "./assets/bid3.png";
// import bestFit from "./assets/best-fit.png";
// import LandingImage from "./LandingImage";
// import ringImage from "./assets/ring-bg.png"
import { BsArrowRight } from "react-icons/bs";

const LandingSection = ({ toggleModal }) => {
  return (
    <div className="landing-page-section">
      <div className="content-wrapper">
        <h1
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
          className="header-text"
        >
          The B2B Operating System for
          <span className="emphasis"> supply chain management </span>
          in Africa.
        </h1>
        <p
          data-aos="fade-up"
          // data-aos-delay="500"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
          className="text-content"
        >
          Multonion makes it easier, cheaper and faster for vendors to access
          the market and fulfil the supply needs of big corporations,
          not-for-profit and government organisations in Africa
        </p>
        <div
          data-aos="fade-up"
          // data-aos-delay="500"
          data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
        >
          <a href="https://app.multonion.com/sign-up">
            <button className="join-waitlist-btn" onClick={() => {}}>
              Sign Up as a Buyer, Seller or Both
              <BsArrowRight style={{ size: "2rem" }} />
            </button>
          </a>
        </div>
      </div>
      <div className="landing-image-wrapper">
        <img src={landingImg} alt="supply-chain" />
        {/* <LandingImage image={bid1} delay={500} x="-20px" y="20px" />
        <LandingImage image={bid2} delay={800} x="10px" y="-80px" />
        <LandingImage image={bid3} delay={1200} x="35%" y="-10%" />
        <LandingImage image={bestFit} delay={1500} x="20%" y="10%" /> */}
      </div>
    </div>
  );
};

export default LandingSection;
