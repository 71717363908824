import { useEffect, useState } from "react";
import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "./assets/Multonion-Logo.png";
import Wrapper from "./Wrapper";
import LandingSection from "./LandingSection";
import Footer from "./Footer";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";
import TestimonialsAndStats from "./TestimonialsAndStats";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import FormModal from "./FormModal";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [isOpen, toggleModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleModalFunction = () => toggleModal(!isOpen);
  const [state, setState] = useState(false);

  const toggleMenuState = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setState(isOpen);
  }, [isOpen]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const detectSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
  }, [screenWidth]);

  return (
    <div
      className="landing-wrapper"
      style={{
        overflowY: state ? "hidden" : "auto",
        scrollBehavior: "smooth",
        maxWidth: "100vw",
      }}
    >
      <header>
        <div className="nav-header-wrapper">
          <img src={logo} alt="multonion" />
          {screenWidth < 948 ? (
            <button
              className="menu-btn"
              onClick={() => toggleMenuState()}
              style={{ color: "#003049" }}
            >
              {isMenuOpen ? <MdClose /> : <HiOutlineMenuAlt1 />}
            </button>
          ) : (
            <div className="desktop-navigation">
              {" "}
              <ul className="navigation-wrapper">
                <li>
                  {" "}
                  <a href="#contact">Contact Us</a>
                </li>
              </ul>
              <a href="https://app.multonion.com/sign-in">
                <button className="nav-waitlist-btn" onClick={() => {}}>
                  Sign In
                </button>
              </a>
            </div>
          )}
        </div>
        {isMenuOpen && screenWidth < 948 && (
          <div className="mobile-navigation">
            {" "}
            <ul className="navigation-wrapper">
              <li>
                {" "}
                <a
                  href="#contact"
                  onClick={() => {
                    toggleMenuState();
                  }}
                >
                  Contact Us
                </a>
              </li>
            </ul>
            <a href="https://app.multonion.com/sign-in">
              <button className="nav-waitlist-btn" onClick={() => {}}>
                Sign In
              </button>
            </a>
          </div>
        )}
      </header>
      <Wrapper>
        <FormModal isOpen={isOpen} toggleModal={toggleModalFunction} />
        <LandingSection toggleModal={toggleModalFunction} />
        <FirstSection toggleModal={toggleModalFunction} />
        <SecondSection toggleModal={toggleModalFunction} />
        <TestimonialsAndStats toggleModal={toggleModalFunction} />
        {/* <Faq /> */}
      </Wrapper>
      <Footer />
    </div>
  );
}

export default App;
