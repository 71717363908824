import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Circles } from "react-loader-spinner";
import logo from "./assets/Multonion-Logo.png";

const FormModal = ({ isOpen, toggleModal }) => {
  return (
    <div
      className="modal-wrapper"
      style={{
        display: isOpen ? "flex" : "none",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className="form-wrapper"
        data-aos="fade-up"
        data-aos-delay="500"
        data-aos-anchor-placement="bottom-bottom"
        data-aos-duration="800"
      >
        <div className="form-wrapper-header">
          <img src={logo} alt="multonion" />
          <button
            className="cancel-modal-btn"
            style={{ fontSize: "30px" }}
            onClick={() => toggleModal()}
          >
            <AiOutlineCloseCircle />
          </button>
        </div>
        <div className="form-body-wrapper">
          <div className="AW-Form-1127788680">
            <div className="loader-wrapper">
              <Circles color="#b46bb1" />
            </div>
          </div>
        </div>
      </div>
      <script type="text/javascript">
        {(function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = "//forms.aweber.com/form/80/1127788680.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "aweber-wjs-p09n5wh93")}
      </script>
    </div>
  );
};

export default FormModal;
