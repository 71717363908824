import React from "react";
import { FaLocationArrow, FaPhoneSquareAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="copyright" id="contact">
        <Body>
          Multonion makes it easier, cheaper and faster for any business that
          <br></br>
          supply goods or services to access business opportunities and fulfil
          <br></br>
          the procurement needs of big corporations, not-for-profits and
          <br></br>
          governments in Africa.
        </Body>
        <p>© 2022 | Multonion Ltd.</p>
        <a
          href="https://www.multonion.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          RC - 1783024
        </a>
      </div>
      <div className="contact-us">
        {" "}
        <Header>Address</Header>
        <Body>
          No. 57, Hillview Estate Zone 1, Lifecamp Extension, Abuja, Nigeria.
        </Body>
        <Header>Phone Contact</Header>
        <Body>+234-9-2915567 | +234 909 959 7661</Body>
        <Header>Contact Us</Header>
        <a href="mailto:contactus@multonion.com">
          <Body>contactus@multonion.com</Body>
        </a>
      </div>
      <div className="contact-us">
        {" "}
        <Header>Legal</Header>
        <a href="https://app.multonion.com/terms-and-conditions">
          <Body>Terms and Conditions</Body>
        </a>
        <a href="https://app.multonion.com/privacy-policies">
          <Body>Privacy Policies</Body>
        </a>
        <Header>Follow us On</Header>
        <a href="https://www.linkedin.com/company/multonion">
          <Body>LinkedIn</Body>
        </a>
      </div>
    </footer>
  );
};

export default Footer;

export const Header = (props) => {
  return <p style={{ fontSize: "16px", margin: 0 }}>{props.children}</p>;
};
export const Body = (props) => {
  return (
    <p
      style={{
        fontWeight: 400,
        fontSize: "14px",
        color: "#c9d3d8",
        marginBottom: "0.5rem",
      }}
    >
      {props.children}
    </p>
  );
};
