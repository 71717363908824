import React from "react";
import rightCircle from "./assets/right-circle.png";
import teamCard from "./assets/teams.png";
import bestFit from "./assets/best-fit.png";
import buyer from "./assets/buyer-image.png";
import vendor from "./assets/vendors.png";
import verified from "./assets/verified.png";
import bids from "./assets/bids.png";
import H2 from "./components/H2";
import { BsArrowRight } from "react-icons/bs";

const SecondSection = ({ toggleModal }) => {
  const ValuesForBuyer = [
    "Outsource procurement (procurement as-a-service)",
    "Manage full procurement life cycle  (Planning, Market Research or Requirement Definition, Tendering & Sourcing, Evaluation & Selection, Contract Award & Contract Management)",
    "Run On-demand procurement. Real-time matching of suppliers to buyer's requests.",
    "Access verified suppliers and their profiles.",
    "Manage requisition process from initiation to approval of purchase order",
    "Manage solicitation process (open, restricted and direct) from draft , to review and approval",
    "Manage evaluation of technical and financial bids using our flexible and customizable evaluation criteria and template",
    "Manage negotiations, final approval and award of contract",
    "Undertake contract management",
    "Manage team (People, Positions & Process)",
    // "Do procurement as-a-service",
    // "Award completion certificates",
    // "Manage the publishing of RFXs and approvals channels",
    // "Receive and review submitted bids from verified bidders.",
    // "Set new criteria or use our industry verified criteria to Evaluate submitted bids in-app",
    // "Award contract and Monitor Implementation",
    // "Award completion certificates",
    // "Artificial Intelligence / Machine Learning algorithm for cross matching",
  ];
  const ValuesForSeller = [
    "Bid for industry specific purchasing requests from buyers (big corporations, Not for Profit/NGOs,  government)",
    "Make real-time bids for buyers’ on-demand request.",
    "Access to credit facilities based on your business performance history.",
    "Become eligible for business documents verification and listing in our supplier database.",
    "Get matched to restricted tenders based on your profile.",
    "Become a verified vendor/supplier for specific sectors",
    "Access franchise, joint venture, partnerships and distributorship opportunities",
    "Access business development and capability building opportunities.",
    "Manage your core team (People, Positions & Processes)",
  ];

  return (
    <div className="value-proposition section-wrapper">
      <div>
        <H2>
          As a <span className="emphasis"> Procuring Entity/Buyer,</span> you
          can
        </H2>
        {ValuesForBuyer.map((Value, i) => (
          <div
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="800"
            className="buyer-values"
            key={i}
          >
            <img src={rightCircle} alt="marker" width="30rem" height="30rem" />
            <p style={{ margin: "0" }}>{Value}</p>
          </div>
        ))}
      </div>
      <div className="buyer-images">
        {" "}
        <img
          src={buyer}
          alt="happy user"
          data-aos="fade-left"
          data-aos-delay="100"
          // data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
          style={{ transform: "translate(50px, 50px)" }}
        />
        <img
          data-aos="fade-up"
          data-aos-delay="200"
          // data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
          src={teamCard}
          alt="team-management"
          style={{
            position: "absolute",
            width: "220px",
            height: "auto",
            left: "-3rem",
            bottom: "0.5rem",
          }}
          className="extra-images"
        />
        <img
          data-aos="fade-up"
          data-aos-delay="300"
          // data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
          src={bestFit}
          alt="best-fit-seller"
          style={{
            position: "absolute",
            width: "200px",
            height: "auto",
            top: "13rem",
            right: "-11rem",
          }}
          className="extra-images"
        />
      </div>

      <div className="buyer-images extra-images">
        {" "}
        <img
          src={vendor}
          alt="vendors"
          data-aos="fade-left"
          data-aos-delay="500"
          // data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
          style={{ transform: "translate(50px, 50px)" }}
        />
        <img
          data-aos="fade-up"
          data-aos-delay="500"
          // data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
          src={bids}
          alt="team-management"
          style={{
            position: "absolute",
            width: "220px",
            height: "auto",
            left: "-3rem",
            bottom: "-4.5rem",
          }}
          className="extra-images"
        />
        <img
          data-aos="fade-up"
          data-aos-delay="400"
          // data-aos-anchor-placement="bottom-bottom"
          data-aos-duration="800"
          src={verified}
          alt="best-fit-seller"
          style={{
            position: "absolute",
            width: "200px",
            height: "auto",
            top: "13rem",
            right: "-8rem",
          }}
          className="extra-images"
        />
      </div>
      <div>
        <H2>
          As a <span className="emphasis"> Vendor/Supplier </span> you can{" "}
        </H2>
        {ValuesForSeller.map((Value, i) => (
          <div
            className="buyer-values"
            key={i}
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="800"
          >
            <img src={rightCircle} alt="marker" width="30rem" height="30rem" />
            <p style={{ margin: 0 }}>{Value}</p>
          </div>
        ))}
        <a href="https://app.multonion.com/sign-up">
          <button
            className="join-waitlist-btn"
            data-aos="fade-up"
            data-aos-delay="800"
            data-aos-duration="800"
            onClick={() => {}}
            style={{
              marginTop: "1.8rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Sign Up Now
            <BsArrowRight style={{ size: "2rem" }} />
          </button>
        </a>
      </div>
    </div>
  );
};

export default SecondSection;
